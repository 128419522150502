import FoundedIn from "../../components/layouts/About/FoundedIn";
import HeroAbout from "../../components/layouts/About/HeroAbout";
import Mission from "../../components/layouts/About/Mission";
import OurSpaces from "../../components/layouts/About/OurSpaces";
import QualityStatement from "../../components/layouts/About/QualityStatement";
import SofreshWay from "../../components/layouts/About/SofreshWay";
import Tractions from "../../components/layouts/About/Tractions";
import Values from "../../components/layouts/About/Values";
import HomeSubscription from "../../components/layouts/Home/HomeSubscription";
import Layout from "../../components/layouts/Layout";

const AboutUs = () => {
  return (
    <Layout
      title={"Healthy Food Made for You"}
      description={"The So Fresh Story"}
    >
      <main>
        <HeroAbout />
        <Tractions />
        <FoundedIn />
        <SofreshWay />
        <Mission />
        <Values />
        <OurSpaces />
        <QualityStatement />
        <HomeSubscription />
      </main>
    </Layout>
  );
};

export default AboutUs;
