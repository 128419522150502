import Button from "../../components/common/Button";

const ErrorPage = () => {
  return (
    <section
      className={`h-screen bg-[#fff] py-[40px] flex items-center justify-center`}
    >
      <div className="container">
        <div className="sm:w-11/12 w-full text-center">
          <div
            className={`h-[50dvh] font-serif bg-404-background bg-no-repeat bg-center bg-contain`}
          >
            <h1 className="text-[80px] text-center pt-10">404</h1>
          </div>

          <div className={`mt-[-120px] xl:mt-[-100px]`}>
            <h2 className="text-[32px]">Look like you're lost</h2>

            <p>the page you are looking for is not available!</p>

            <div className="w-full flex items-center justify-center pt-6">
              <Button linkHref="/" linkText="Go to Home" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
