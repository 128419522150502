// REACT & DEFAULTS
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

// COMPONENTS
import Layout from "../../components/layouts/Layout";
import SingleOrderDetailsCard from "../../components/common/SingleOrderDetailsCard";
import { formatToNigerianNaira } from "../../lib/utlis/helper";
import { getSingleOrder } from "../../lib/handlers/orderHandler";

// IMAGES & ICONS
import FoodDelivery from "../../assests/images/OrderHistoryPage/foodDelivery.png";

// STYLES
import styles from "./SingleOrderDetails.module.scss";
import { Oval } from "react-loader-spinner";

const SingleOrderDetails = () => {
  let { reference } = useParams();
  const [cookies] = useCookies<string>(["sofresh-token"]);

  // GET SINGLE MENU/PRODUCT
  const { data: singleOrderData, isLoading: isSingleOrderDataLoading } =
    useQuery(["single-order"], () =>
      getSingleOrder({
        token: cookies["sofresh-token"],
        reference,
      })
    );

  const convertExtrasPricesToArray = (extras: Array<number>) => {
    return extras
      .map((item: any) => item.total_amount)
      .reduce((accumulator: string, currentValue: string) => {
        const prevValue = Number(accumulator);
        const value = Number(currentValue);
        return prevValue + value;
      }, 0);
  };

  const totalPrice =
    singleOrderData &&
    Number(singleOrderData.total_amount) + Number(singleOrderData.delivery_fee);

  const formattedStartDate = new Date(
    singleOrderData?.order_details[0]?.delivery_start_date
  ).toLocaleString();

  return (
    <Layout
      title={"Healthy Food Made for You"}
      description={"Order History Page"}
    >
      <main className={styles.singleOrderDetails}>
        {isSingleOrderDataLoading ? (
          <div className="w-full flex items-center justify-center my-32">
            <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
          </div>
        ) : (
          <section
            className={`container ${styles.singleOrderDetailsContainer}`}
          >
            <div className={styles.orderDetailsCards}>
              <>
                {singleOrderData.order_details &&
                  singleOrderData.order_details.map((order: any) =>
                    order.product && order.plan_type === null ? (
                      <SingleOrderDetailsCard
                        key={order.id}
                        storeID={singleOrderData?.store?.id}
                        product_id={order.product.id}
                        imageUrl={order.product.image[0]}
                        title={`${order.product.name}`}
                        details={order.extras.map((items: any) => items.extra)}
                        quantity={order.quantity}
                        price={
                          Number(order.total_amount) +
                          (order?.extras
                            ? convertExtrasPricesToArray(order?.extras)
                            : 0)
                        }
                      />
                    ) : (
                      <SingleOrderDetailsCard
                        key={order.plan_type.id}
                        storeID={singleOrderData?.store?.id}
                        plan_type_id={order.plan_type.id}
                        imageUrl={order.plan_type.image[0]}
                        title={order.plan_type.name}
                        details={order.duration}
                        quantity={1}
                        price={order.total_amount}
                      />
                    )
                  )}
              </>

              <div className={styles.reciepientsCard}>
                <div className={styles.reciepientsCardHeader}>
                  <h4 className={styles.mealTotalPriceTitle}>
                    Order Recipient Information
                  </h4>
                </div>

                <div className={styles.reciepientsCardItem}>
                  <p className={styles.reciepientsCardItemTitle}>Store Name</p>
                  <span className={styles.cardItemDivider}></span>
                  <p className={styles.reciepientsCardItemText}>
                    {singleOrderData?.store?.store_name &&
                      singleOrderData?.store?.store_name}
                  </p>
                </div>

                <div className={styles.reciepientsCardItem}>
                  <p className={styles.reciepientsCardItemTitle}>
                    Order Reference
                  </p>
                  <span className={styles.cardItemDivider}></span>
                  <p className={styles.reciepientsCardItemText}>
                    {`${singleOrderData?.reference}`}
                  </p>
                </div>

                <div className={styles.reciepientsCardItem}>
                  <p className={styles.reciepientsCardItemTitle}>
                    Reciever&apos;s Name
                  </p>
                  <span className={styles.cardItemDivider}></span>
                  <p className={styles.reciepientsCardItemText}>
                    {singleOrderData?.delivery_type === "pickup"
                      ? singleOrderData?.user?.name
                      : singleOrderData?.meta?.recipient?.recipient_name
                      ? singleOrderData?.meta?.recipient?.recipient_name
                      : singleOrderData?.user?.name}
                  </p>
                </div>

                {singleOrderData?.user?.meta?.allergies && (
                  <div className={styles.reciepientsCardItem}>
                    <p className={styles.reciepientsCardItemTitle}>Allergies</p>
                    <span className={styles.cardItemDivider}></span>
                    <p className={styles.reciepientsCardItemText}>
                      {singleOrderData?.user?.meta?.allergies}
                    </p>
                  </div>
                )}

                {singleOrderData?.order_details[0]?.meta?.plan_preference && (
                  <div className={styles.reciepientsCardItem}>
                    <p className={styles.reciepientsCardItemTitle}>
                      Plan Prefernces
                    </p>
                    <span className={styles.cardItemDivider}></span>
                    <p className={styles.reciepientsCardItemText}>
                      {singleOrderData?.order_details[0]?.meta?.plan_preference}
                    </p>
                  </div>
                )}

                {singleOrderData?.order_details[0]?.delivery_start_date && (
                  <div className={styles.reciepientsCardItem}>
                    <p className={styles.reciepientsCardItemTitle}>
                      Plan Start Date/Time
                    </p>
                    <span className={styles.cardItemDivider}></span>
                    <p className={styles.reciepientsCardItemText}>
                      {formattedStartDate}
                    </p>
                  </div>
                )}

                {singleOrderData?.order_details[0]?.duration && (
                  <div className={styles.reciepientsCardItem}>
                    <p className={styles.reciepientsCardItemTitle}>
                      Plan Duration
                    </p>
                    <span className={styles.cardItemDivider}></span>
                    <p className={styles.reciepientsCardItemText}>
                      {`${singleOrderData?.order_details[0]?.duration} Day(s)`}
                    </p>
                  </div>
                )}

                {singleOrderData?.user?.meta?.preferences && (
                  <div className={styles.reciepientsCardItem}>
                    <p className={styles.reciepientsCardItemTitle}>
                      Preferences
                    </p>
                    <span className={styles.cardItemDivider}></span>
                    <p className={styles.reciepientsCardItemText}>
                      {singleOrderData?.user?.meta?.preferences}
                    </p>
                  </div>
                )}

                {singleOrderData?.user?.meta?.expectant_mum && (
                  <div className={styles.reciepientsCardItem}>
                    <p className={styles.reciepientsCardItemTitle}>Gender</p>
                    <span className={styles.cardItemDivider}></span>
                    <p className={styles.reciepientsCardItemText}>
                      {singleOrderData?.user?.meta?.expectant_mum
                        ? "Female"
                        : "Male"}
                    </p>
                  </div>
                )}

                {singleOrderData?.user?.meta?.expectant_mum && (
                  <div className={styles.reciepientsCardItem}>
                    <p className={styles.reciepientsCardItemTitle}>
                      Expectant Mum?
                    </p>
                    <span className={styles.cardItemDivider}></span>
                    <p className={styles.reciepientsCardItemText}>
                      {singleOrderData?.user?.meta?.expectant_mum
                        ? singleOrderData?.user?.meta?.expectant_mum
                        : "No"}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.priceCard}>
                <div className={styles.priceCardItem}>
                  <p className={styles.mealCostTitle}>Meal Cost</p>
                  <span className={styles.cardItemDivider}></span>
                  <p className={styles.mealcostPrice}>
                    {formatToNigerianNaira(singleOrderData.total_amount)}
                  </p>
                </div>

                <div className={styles.priceCardItem}>
                  <p className={styles.mealDeliveryFeeTitle}>Delivery Fee</p>
                  <span className={styles.cardItemDivider}></span>
                  <p className={styles.mealdeliveryFeePrice}>
                    {formatToNigerianNaira(singleOrderData.delivery_fee)}
                  </p>
                </div>

                <div className={styles.priceCardItem}>
                  <p className={styles.mealTotalPriceTitle}>Total Cost</p>
                  <span className={styles.cardItemDivider}></span>
                  <p className={styles.mealtotalPrice}>
                    {formatToNigerianNaira(totalPrice)}
                  </p>
                </div>
              </div>

              {/* <button className={styles.reOrderBtn}>Re-order This Meal</button> */}
            </div>

            <figure className={styles.orderDetailsIllustrationContainer}>
              <img
                className={styles.orderDetailsIllustration}
                src={FoodDelivery}
                alt="delivery"
              />

              <figcaption
                className={styles.orderDetailsIllustrationTextContainer}
              >
                <p className={styles.orderDetailsIllustrationText}>
                  {`Meal ${
                    singleOrderData?.delivery_type === "delivery"
                      ? "delivered to"
                      : "picked up at"
                  } 
                  ${singleOrderData?.delivery_address}. ${singleOrderData?.created_at}`}
                </p>
              </figcaption>
            </figure>
          </section>
        )}
      </main>
    </Layout>
  );
};

export default SingleOrderDetails;
