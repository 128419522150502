import CartItemCard from "../../components/common/CartItemCard";
import CartItemsSummary from "../../components/common/CartItemsSummary";
import Layout from "../../components/layouts/Layout";
import styles from "./Cart.module.scss";

const Cart = () => {
  return (
    <Layout title={"Healthy Food Made for You"} description={"Cart Page"}>
      <main className={styles.cartPage}>
        <section className={`container ${styles.cart}`}>
          <h1 className={styles.cartTitle}>My Cart</h1>

          <div className={styles.cartContent}>
            <div className={styles.cartItemsContainer}>
              <CartItemCard />
            </div>

            <div className={styles.cartItemsSummaryContainer}>
              <CartItemsSummary />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Cart;
