import { useQuery } from "@tanstack/react-query";
import locator from "../../../../assests/images/LocationsPage/locator.svg";
import LocationCard from "../LocatorCard";
// import locations from "../outlets";
import { getLocations } from "../../../../lib/handlers/locationHandler";

import { Oval } from "react-loader-spinner";
import { useEffect, useState } from "react";

const Location = () => {
  const [locatorImg, setLocatorImg] = useState(locator);
  const [locations, setLocations] = useState<TStoreLocationData[]>([]);
  // GET ALL LOCATIONS
  const { data: allLocations, isLoading } = useQuery(["locations"], () =>
    getLocations<TStoreLocationData[]>()
  );

  function handleSearch(search: string) {
    if (allLocations) {
      setLocations(
        allLocations.filter(
          (location) =>
            location.name.toLowerCase().includes(search.toLowerCase()) ||
            location.address.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }

  useEffect(() => {
    if (allLocations) {
      setLocations(allLocations);
    }
  }, [allLocations]);

  return (
    <section className="bg-[#fffaf4]">
      <div className="container flex">
        <div className="pt-16 px-6 md:px-0">
          <div className="w-full md:w-1/2">
            <div className="relative flex items-center w-[75%] h-12 border border-[#E5DFD7] rounded-[4px] focus-within:shadow-lg overflow-hidden">
              <input
                className="peer h-full w-full outline-none bg-[transparent] pl-2"
                type="text"
                id="search"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div className="grid place-items-center h-full w-12 text-[#F27E0A]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <h4 className="my-4 text-[#041D0566] text-[14px] leading-[17px] font-medium">
            Found{" "}
            {allLocations && allLocations.length > 0 ? allLocations.length : 0}{" "}
            store
            {allLocations && allLocations.length < 2 ? "" : "s"}
          </h4>

          <div className="w-full grid grid-cols-1 lg:grid-cols-2">
            <div className="w-full h-[520px] overflow-y-auto flex flex-col gap-4">
              {isLoading ? (
                <div className="my-5 w-full h-full flex  items-center justify-center">
                  <Oval height="40" width="40" color="#495057" visible={true} />
                </div>
              ) : (
                <>
                  {locations?.map((location) => {
                    if (location.is_active) {
                      return (
                        <LocationCard
                          key={location.id}
                          imageSrc={location.image}
                          name={location.name}
                          address={location.address}
                          services={location.delivery_option}
                          phone={location.phone_no}
                          setImage={setLocatorImg}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
            </div>

            <div className="w-full h-[300px] md:h-[520px] flex py-10 lg:py-0">
              <img
                src={locatorImg}
                alt="location"
                className="w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
