import { axiosClient } from "../utlis/axiosClient";

export type CategoryData = {
  description: string;
  id: number;
  is_active: boolean;
  meta: unknown;
  slug: string;
  title: string;
};

export type BlogPostDataResponse = {
  article: string;
  category: CategoryData;
  created_at: string;
  id: number;
  images: Array<string>;
  is_active: boolean;
  meta: unknown;
  lug: string;
  title: string;
};

export type BlogsData = {
  article: string;
  created_at: string;
  id: number;
  images: Array<string>;
  is_active: boolean;
  meta: unknown;
  slug: string;
  title: string;
};

export type CategoriesWithBlogsDataResponse = {
  description: string;
  created_at: string;
  id: number;
  images: Array<string>;
  is_active: boolean;
  meta: unknown;
  slug: string;
  title: string;
  blogs: Array<BlogsData>;
};

export const getLatestBlogs = async (): Promise<Array<BlogsData>> => {
  try {
    const { data } = await axiosClient.get<{ data: Array<BlogsData> }>(
      `public/blogs`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return data.data;
  } catch (error: unknown) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getSingleBlogPost = async (
  slug: string,
): Promise<BlogPostDataResponse> => {
  try {
    const { data } = await axiosClient.get<{ data: BlogPostDataResponse }>(
      `public/blogs/${slug}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return data.data;
  } catch (error: unknown) {
    console.error({ error });
    throw new Error("An error occurred. Please try again.");
  }
};

export const getCategoriesWithBlogs = async (): Promise<
  Array<CategoriesWithBlogsDataResponse>
> => {
  try {
    const { data } = await axiosClient.get<{
      data: Array<CategoriesWithBlogsDataResponse>;
    }>(`public/blog-categories`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error: unknown) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getCategoryWithBlogs = async (slug:string): Promise<
  CategoriesWithBlogsDataResponse
> => {
  try {
    const { data } = await axiosClient.get<{
      data: CategoriesWithBlogsDataResponse;
    }>(`public/blog-categories/${slug}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error: unknown) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};