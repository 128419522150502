// COMPONENTS, UTILS& EXTERNAL LIBs
import Layout from "../../components/layouts/Layout";
import Location from "../../components/layouts/Locations/Location";

const Home = () => {
  return (
    <Layout title={"Healthy Food Made for You"} description={"Locations Page"}>
      <main>
        <Location />
      </main>
    </Layout>
  );
};

export default Home;
