import { useParams } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
// import BlogImage from '../../assests/images/blog_img.png'
import { getSingleBlogPost } from "../../lib/handlers/blogs";
import { useQuery } from "@tanstack/react-query";
import parse from "html-react-parser";
import { useEffect, useLayoutEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import HomeSubscription from "../../components/layouts/Home/HomeSubscription";

const SingleBlog = () => {
  const { slug } = useParams();
  const [pageSlug, setPageSlug] = useState("");

  useEffect(() => {
    if (slug) {
      // console.log('SLUG', slug)
      setPageSlug(slug);
    }
  }, [slug]);

  const { data: blogData, isLoading: isBlogDataLoading } = useQuery(
    ["single-blog", slug],
    () => getSingleBlogPost(pageSlug),
    { enabled: Boolean(pageSlug) }
  );
  // console.log("BLOG_DATA:", blogData);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Layout title="Healthy Food Made for You" description="Blogs Page">
      <main className="container">
        <section className="px-3 py-10">
          {isBlogDataLoading ? (
            <div className="w-full flex items-center justify-center my-32">
              <Oval
                wrapperClass="ml-2"
                height={50}
                width={50}
                strokeWidth={5}
              />
            </div>
          ) : blogData ? (
            <section key={blogData.title}>
              <figure className="w-full flex flex-col items-center justify-center rounded-lg">
                <div className="w-full max-h-[400px] flex object-cover overflow-hidden rounded-lg">
                  <img
                    className="w-full rounded-lg"
                    src={blogData.images[0]}
                    alt={blogData.title}
                  />
                </div>

                <figcaption className="w-full flex items-center justify-between py-4">
                  <span className="w-fit bg-[#F4E4D7] text-[#A17759] text-xs font-semibold leading-[1.4] rounded-[4px] px-[10px] py-[5px]">
                    {blogData.category
                      ? blogData.category.title
                      : "Blog Category"}
                  </span>

                  <p className="text-[#041D05] text-[14px] font-semibold leading-[1.4]">
                    <span>Published On</span>

                    <span className="text-[#339E35]">{` ${blogData.created_at}`}</span>
                  </p>
                </figcaption>
              </figure>

              <div className="w-full flex flex-col gap-4 py-8">
                <h1 className="text-[#041D05] text-[35px] font-semibold leading-[1.4] pb-8">
                  {blogData.title}
                </h1>

                <div className="text-[#041D05] leading-[1.6]">
                  {parse(blogData.article)}
                </div>
              </div>
            </section>
          ) : (
            <div className="w-full flex items-center justify-center my-32">
              <p>No Data</p>
            </div>
          )}
        </section>
      </main>

      <HomeSubscription />
    </Layout>
  );
};

export default SingleBlog;
